<template>
  <v-app-bar v-if="user" color="transparent" height="80" app absolute flat>
    <PiedBreadcrumb />

    <v-toolbar-title
      class="fontUpper2 font-weight-black"
      :class="{ 'pr-4': isMobile, 'mr-2': !isMobile }"
    >
      <div
        v-if="isToShowPROIcon"
        class="app-bar__pro-icon"
        :style="isMobile ? 'width: 35px' : 'width: 96px'"
      >
        <v-icon size="10px">$vuetify.icons.crown</v-icon>
        <span v-if="!isMobile" class="ml-1 app-bar__pro-icon-text">
          AVANÇADO
        </span>
      </div>
    </v-toolbar-title>

    <Notifications v-if="enableNotificationService" />

    <v-btn
      v-if="enableSupportByWhatsApp"
      target="_blank"
      class="whatsapp"
      plain
      icon
      :href="whatsAppFormatted"
    >
      <v-icon>{{ $icons.WHATSAPP }}</v-icon>
    </v-btn>

    <v-menu
      transition="scroll-y-transition"
      min-width="220px"
      max-width="220px"
      nudge-bottom="4"
      rounded="lg"
      z-index="10"
      close-on-click
      close-on-content-click
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          color="white"
          class="text-none pa-1"
          height="40px"
          :width="isMobile ? '40px' : undefined"
          :rounded="!isMobile"
          :icon="isMobile"
          :x-small="!isMobile"
          style="box-shadow: 0 4px 25px 0 rgba(133, 133, 133, 0.25)"
          :class="{ 'mr-1': !isMobile }"
        >
          <v-icon
            :right="!isMobile"
            size="32px"
            color="button"
            :class="{ 'mr-2': !isMobile }"
          >
            {{ $icons.ACCOUNTCIRCLE }}
          </v-icon>
          <span class="mr-1" v-if="!isMobile" style="font-size: 14px">{{
            user.name
          }}</span>
        </v-btn>
      </template>

      <v-list elevation="0" class="fontUpper2" dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 12px; color: var(--pied-text-color-primary)"
              v-text="`${user.name} ${user.surname}`.trim()"
            />
            <v-list-item-subtitle
              style="font-size: 10px; color: var(--pied-text-color-secondary)"
              v-text="user.email"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          v-for="(item, index) in itemsMenu"
          :key="index"
          @mouseover="hoverMenu = index"
          @mouseleave="hoverMenu = -1"
          @click="(e) => item.onClick(e)"
        >
          <v-list-item-title class="decrease-1">
            <v-icon
              class="decrease-2 pr-2"
              :class="{ 'black--text': hoverMenu !== index }"
            >
              {{ item.icon }}
            </v-icon>
            <span :class="{ 'black--text': hoverMenu !== index }">
              {{ item.title }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { User, Open, PiedAdmin } from "@/request";
import { reloadUserData } from "@/request/http";
import Notifications from "@/components/global/notifications/Notifications.vue";
import { formatWhatsAppContact } from "@/utils/utils";
import PiedBreadcrumb from "@/components/global/PiedBreadcrumb.vue";

export default {
  name: "AppBar",
  components: { Notifications, PiedBreadcrumb },
  data: () => ({
    houverSupport: false,
    houverLog: false,
    hoverMenu: -1,
  }),

  computed: {
    ...mapGetters("userData", { user: "getUserData" }),
    ...mapGetters("theme", ["hasTerms", "getTerm", "isPiedAdmin"]),
    isIntegrator() {
      return this.user?.type === "integrator";
    },
    isAdmin() {
      return this.user?.type === "admin";
    },
    isPiedAdmin() {
      return this.user?.type === "piedAdmin";
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isToShowPROIcon() {
      return (
        this.user?.actions?.extraItem &&
        this.user?.actions?.importRequestFile &&
        !this.isIntegrator
      );
    },
    enableNotificationService() {
      return this.user?.actions?.notificationService && this.isAdmin;
    },
    enableSupportByWhatsApp() {
      return (
        this.isIntegrator &&
        this.user?.actions?.userRoles &&
        this.user?.role?.permissions?.includes("support.whatsApp")
      );
    },
    whatsAppFormatted() {
      let result = formatWhatsAppContact(
        this.user.role.support.whatsApp.number
      );
      if (this.user.role.support.whatsApp.message) {
        const message = this.user.role.support.whatsApp.message
          .split(" ")
          .join("%20");
        result = result.concat("?text=", message);
      }
      return result;
    },
    itemsMenu() {
      const items = [
        {
          title: "Meus Dados",
          icon: "mdi-account",
          onClick: () =>
            this.$redirect({ name: "UserInfo", params: { type: "user" } }),
          show: this.user?.type !== "piedAdmin",
        },
        {
          title: "Minha Empresa",
          icon: "mdi-domain",
          onClick: () =>
            this.$redirect({
              name: "UserInfo",
              params: { type: "company" },
            }),
          show:
            this.isIntegrator && (this.user.cnpj || this.user.company?.cnpj),
        },
        {
          title: "Alterar Senha",
          icon: "mdi-lock",
          onClick: () =>
            this.$redirect({ name: "UserInfo", params: { type: "user" } }),
          show: this.user?.type !== "piedAdmin",
        },
        {
          title: "Termos de Uso",
          icon: "mdi-file-document",
          onClick: (e) => this.openTerms(e),
          show: this.isIntegrator && this.hasTerms,
        },
        {
          title: "Sair",
          icon: "mdi-logout-variant",
          onClick: () => this.logout(),
          show: true,
        },
      ];

      return items.filter((i) => i.show);
    },
  },
  created() {
    reloadUserData();
  },
  methods: {
    async logout() {
      try {
        if (!this.isPiedAdmin) {
          await User.logout();
        } else {
          await PiedAdmin.logout();
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        await this.$redirect({ name: "Login" });
      }
    },
    async openTerms() {
      if (this.hasTerms && this.getTerm.user) {
        if (this.$vuetify.breakpoint.xsOnly) {
          await this.$redirect({ name: "TermsAndConditionsIndex" });
        } else {
          window.open(
            this.$router.resolve({ name: "TermsAndConditionsIndex" }).href,
            "_blank"
          );
        }

        return;
      }

      try {
        const response = await Open.downloadTerms();
        const downloadUrl = window.URL.createObjectURL(response);
        window.open(downloadUrl, "_blank");
        window.URL.revokeObjectURL(downloadUrl);
      } catch (e) {
        console.warn("-= falhou ao visualizar termos =-" + e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-bar__pro-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 21px;
  border: 1px solid var(--v-button-base);
  border-radius: 15px;
  background-color: transparent;
  color: var(--v-button-base);
}

.app-bar__pro-icon-text {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

::v-deep .v-toolbar__content > .v-btn.v-btn--icon:last-child {
  margin-right: 0 !important;
}

.whatsapp:hover {
  color: #25d366;
}

.whatsapp {
  color: #075e54 !important;
}
</style>

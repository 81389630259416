import Icons from "@/constants/Icons";

export default [
  {
    path: "/settings",
    name: "Settings",
    redirect: "/settings/general",
    component: () => import("@/views/GroupRouter.vue"),
    meta: {
      icon: Icons.COG,
      title: "Configurações",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["configuration"],
      isGroup: true,
    },
    children: [
      {
        path: "/settings/distributionCenters",
        component: () => import("@/views/GroupRouter.vue"),
        meta: {
          icon: Icons.DOLLY,
          title: "Centros de Distribuição",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.distributionCenter"],
        },
        children: [
          {
            path: "",
            name: "DistributionCenters",
            component: () =>
              import(
                "@/views/settings/distributionCenter/PageDistributionCenterList.vue"
              ),
            meta: {
              title: "Centros de Distribuição",
            },
          },
          {
            path: "visualize/:id?",
            name: "DistributionCentersVisualize",
            component: () =>
              import(
                "@/views/settings/distributionCenter/PageDistributionCenterVisualize.vue"
              ),
            meta: {},
          },
          {
            path: "deliveryPriority",
            name: "DistributionCentersDeliveryPriority",
            component: () =>
              import(
                "@/views/settings/distributionCenter/PageDistributionCenterDeliveryPriority.vue"
              ),
            meta: {
              title: "Editar Matriz de Priorização",
            },
          },
          {
            path: "distributionRegion/:id?",
            name: "DistributionRegion",
            component: () =>
              import(
                "@/views/settings/distributionCenter/PageDistributionCenterDistributionRegion.vue"
              ),
            meta: {},
          },
        ],
      },
      {
        path: "/settings/extraItems",
        name: "ExtraItems",
        meta: {
          icon: Icons.EXTRAITEM,
          title: "Itens Extras",
          actions: ["extraItem"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.extraItem"],
        },
        component: () => import("@/views/settings/extraItems/ExtraItems.vue"),
      },
      {
        path: "/settings/advertising",
        name: "",
        meta: {
          icon: Icons.ARRANGE,
          title: "Publicidade",
          actions: ["advertising"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.advertising"],
        },
        component: () => import("@/views/GroupRouter.vue"),
        children: [
          {
            path: "",
            name: "AdvertisingSettings",
            component: () =>
              import(
                "@/views/settings/advertising/PageSettingsAdvertising.vue"
              ),
            meta: {
              title: "Publicidade",
              actions: ["advertising"],
              permissions: ["admin", "staff"],
              staffPermissions: ["configuration.advertising"],
            },
            props: true,
          },
          {
            path: "bannerEdit/:id?",
            name: "BannerEdit",
            component: () =>
              import(
                "@/views/settings/advertising/banners/PageSettingsAdvertisingEditBannerNew.vue"
              ),
            meta: {
              title: "Editar Banner",
            },
            props: true,
          },
        ],
      },
      {
        path: "/settings/recomendations",
        name: "RecomendationsSettings",
        meta: {
          icon: Icons.RECOMMENDATION,
          title: "Recomendações",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.recommendations"],
        },
        component: () =>
          import("@/views/settings/recommendation/Recommendation.vue"),
      },
      {
        path: "/settings/precification",
        name: "PrecificationSettings",
        meta: {
          icon: Icons.CASH2,
          title: "Precificação",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.precifications"],
        },
        component: () =>
          import("@/views/settings/precification/Precification.vue"),
      },
      {
        path: "/settings/delivery",
        name: "DeliverySettings",
        meta: {
          icon: Icons.TRUCK,
          title: "Frete",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.freight"],
        },
        component: () => import("@/views/settings/delivery/Delivery.vue"),
      },
      {
        path: "/settings/emails",
        name: "EmailsSettings",
        meta: {
          icon: Icons.EMAIL,
          title: "Automação de E-mail",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.email"],
        },
        component: () => import("@/views/settings/emails/PageEmail.vue"),
      },
      {
        path: "/settings/messages",
        name: "MessagesSettings",
        meta: {
          icon: Icons.OBSERVATION,
          title: "Observações",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.observations"],
        },
        component: () =>
          import("@/views/settings/observation/PageSettingsObservation.vue"),
      },
      {
        path: "/settings/categories",
        name: "CategoriesSettings",
        meta: {
          icon: Icons.CATEGORY,
          title: "Categorias Outros Produtos",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.otherProductsCategories"],
        },
        component: () => import("@/views/settings/categories/Categories.vue"),
      },
      {
        path: "/settings/structures",
        name: "StructuresSettings",
        meta: {
          icon: Icons.STRUCTURE,
          title: "Estruturas",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.structures"],
        },
        component: () => import("@/views/settings/structures/Structures.vue"),
      },
      {
        path: "/settings/shoppingJourney",
        meta: {
          icon: Icons.BAG,
          title: "Jornadas de Compra",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.kits"],
        },
        component: () => import("@/views/GroupRouter.vue"),
        children: [
          {
            path: "kits",
            name: "ShoppingJourneyKitsSettings",
            meta: {
              title: "Jornadas de Compra",
              permissions: ["admin", "staff"],
              staffPermissions: ["configuration.kits"],
            },
            component: () =>
              import("@/views/settings/shoppingJourney/PageSettingsKits.vue"),
          },
          {
            path: "separatedSales",
            name: "ShoppingJourneySeparatedSalesSettings",
            meta: {
              title: "Jornadas de Compra",
              permissions: ["admin", "staff"],
              staffPermissions: ["configuration.kits"],
            },
            component: () =>
              import(
                "@/views/settings/shoppingJourney/PageSettingsSeparatedSales.vue"
              ),
          },
        ],
        redirect: { name: "ShoppingJourneyKitsSettings" },
      },
      {
        path: "/settings/sizing",
        name: "SizingSettings",
        meta: {
          icon: Icons.CALCULATOR,
          title: "Dimensionamento",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.dimension"],
        },
        component: () => import("@/views/settings/sizing/Sizing.vue"),
      },
      {
        path: "/settings/payment",
        meta: {
          icon: Icons.CREDITCARD,
          title: "Checkout de Pagamento",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.checkoutPayment"],
        },
        component: () => import("@/views/GroupRouter.vue"),
        children: [
          {
            path: "",
            name: "PaymentList",
            meta: {
              title: "Checkout de Pagamento",
            },
            component: () =>
              import("@/views/settings/payment/PagePaymentList.vue"),
          },
          {
            path: "addEdit",
            name: "PaymentAddEdit",
            meta: { title: "Adicionar Forma de Pagamento" },
            component: () =>
              import("@/views/settings/payment/PagePaymentAddEdit.vue"),
          },
        ],
      },
      {
        path: "/settings/request",
        name: "RequestSettings",
        meta: {
          icon: Icons.CURRENCY,
          title: "Orçamentos e Pedidos",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.requests"],
        },
        component: () =>
          import("@/views/settings/requests/PageRequestSettings.vue"),
      },
      {
        path: "/settings/request/coupons/:id/details",
        name: "CouponView",
        meta: {
          icon: Icons.VIEWGRID,
          title: "Detalhes do Cupom",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.requests"],
          local: [],
        },
        component: () =>
          import("@/views/settings/requests/coupons/AddEditCoupon.vue"),
      },
      {
        path: "/settings/request/coupons/:id?",
        name: "CouponSettings",
        meta: {
          icon: Icons.VIEWGRID,
          title: "Adicionar Cupom",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.requests"],
          local: [],
        },
        beforeEnter(to, from, next) {
          let title = to.meta?.title || "";

          if (to.name === "CouponSettings" && to.params?.id) {
            title = "Editar Cupom";
          } else {
            title = "Adicionar Cupom";
          }

          document.title = document.title.replace(/(\s\w+){1,}$/, ` ${title}`);
          return next();
        },
        component: () =>
          import("@/views/settings/requests/coupons/AddEditCoupon.vue"),
      },
      {
        path: "/settings/templates",
        name: "Modelo de Orçamento",
        meta: {
          icon: Icons.FILEDOCUMENTMULTIPLE,
          title: "Modelo de Orçamento",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.requestModel"],
        },
        component: () => import("@/views/settings/Templates.vue"),
      },
      {
        path: "/settings/status",
        component: () => import("@/views/GroupRouter.vue"),
        meta: {
          icon: Icons.COGS,
          isBeta: true,
          title: "Status das negociações",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: [
            "configuration.budgetStatus",
            "configuration.orderStatus",
          ],
        },
        children: [
          {
            path: "",
            name: "SettingStatusNegotiations",
            meta: {
              title: "Status das negociações",
              permissions: ["admin", "staff"],
              staffPermissions: [
                "configuration.budgetStatus",
                "configuration.orderStatus",
              ],
            },
            component: () =>
              import("@/views/settings/status/SettingsStatusNegotiation.vue"),
          },
          {
            path: "edit/:type/:id?",
            name: "SettingStatusNegotiationsEdit",
            meta: {
              title: "Status das negociações",
              permissions: ["admin", "staff"],
              staffPermissions: [
                "configuration.budgetStatus",
                "configuration.orderStatus",
              ],
            },
            props: true,
            component: () =>
              import(
                "@/views/settings/status/SettingStatusNegotiationEdit.vue"
              ),
          },
        ],
      },
      {
        path: "/settings/orderMessages",
        name: "OrderMessages",
        meta: {
          icon: Icons.FORMATBULLETED,
          title: "Mensagens de Pedido",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.messages"],
        },
        component: () => import("@/views/settings/OrderMessages.vue"),
      },
      {
        path: "/settings/theme",
        name: "ThemeSettings",
        meta: {
          icon: Icons.IDVISUAL,
          title: "Temas (ID Visual)",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.customTheme"],
        },
        component: () => import("@/views/settings/theme/PageTheme.vue"),
      },
      {
        path: "/settings/sideBar",
        name: "SideBarSettings",
        meta: {
          icon: Icons.TABLE,
          title: "Aba Lateral",
          actions: ["customIntegratorSideBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.sideBar"],
        },
        component: () => import("@/views/settings/sideBar/SideBar.vue"),
      },
      {
        path: "/settings/Roles",
        name: "RolesSettings",
        meta: {
          icon: Icons.ACCOUNTCOG,
          title: "Perfis de Usuários",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.userProfiles"],
        },
        component: () => import("@/views/settings/roles/Roles.vue"),
      },
      {
        path: "/settings/general",
        name: "GeneralSettings",
        meta: {
          icon: Icons.GENERAL,
          title: "Geral",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.main"],
        },
        component: () => import("@/views/settings/General.vue"),
      },
      {
        path: "/settings/crmModule",
        name: "CrmModule",
        meta: {
          icon: Icons.ACCOUNTTIEVOICE,
          title: "Módulo CRM",
          actions: ["enableCRM"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.crm"],
        },
        component: () => import("@/views/settings/crmModule/CrmModule.vue"),
      },
      {
        path: "/settings/api",
        name: "API",
        meta: {
          icon: Icons.KEY,
          title: "API",
          actions: ["basicAPI"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.api"],
        },
        component: () => import("@/views/settings/api/Api.vue"),
      },
      {
        path: "/settings/termsAndConditions",
        name: "TermsAndConditions",
        meta: {
          icon: Icons.FILEDOCUMENT,
          title: "Termos de uso",
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.termsOfService"],
        },
        component: () =>
          import("@/views/settings/termsAndConditions/TermsAndConditions.vue"),
        children: [
          {
            path: "/settings/termsAndConditions/:id",
            name: "AddEditTermsAndConditions",
            meta: {
              title: "Cadastrar Termos de Uso",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["configuration.termsOfService"],
            },
            component: () =>
              import("@/views/settings/termsAndConditions/AddEditTerms.vue"),
          },
        ],
      },
    ],
  },
];

import store from "@/store";

const STATUS_TO_BLOCK_EDITION = {
  DEFAULT: {
    value: "defaultNotRestrictRequest",
    name: "Padrão (Sem Bloqueio)",
  },
};

const getDistributorCustomStatus = (type = "budget", distributorId = null) =>
  store.dispatch("dealStatus/fetchDealStatus", {
    type,
    distributorId,
  });

export const isMoveDealStatus = (dealStatus) => {
  const user = store.getters["userData/getUserData"];
  if (user.type === "admin" || !dealStatus.tags.length) return true;
  const permissions = [`move:profile:${user.role.id}`, `move:user:${user.id}`];
  return dealStatus.tags.some((tag) => permissions.includes(tag));
};

export { getDistributorCustomStatus, STATUS_TO_BLOCK_EDITION };

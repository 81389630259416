<template>
  <v-row no-gutters class="ml-2">
    <v-col cols="12">
      <div
        v-for="(item, index) in path"
        class="pied-breadcrumbs__text-container"
      >
        <div
          v-if="index"
          class="pied-breadcrumb__divisor pied-breadcrumb-father-text__color"
        >
          /
        </div>
        <div
          v-if="!hasRoute(item) && index + 1 < path.length"
          class="pied-breadcrumbs__text pied-breadcrumb-father-text__color"
        >
          {{ item.text }}
        </div>
        <div
          v-else-if="index + 1 < path.length"
          class="pied-breadcrumbs__text pied-breadcrumbs__text-hover pied-breadcrumb-father-text__color"
          @click="$router.push({ name: item.routeName, params: item.params })"
        >
          {{ item.text }}
        </div>
        <div
          v-else
          class="pied-breadcrumbs__text pied-breadcrumb-current-text__color"
          @click="$router.push({ name: item.routeName, params: item.params })"
        >
          {{ item.text }}
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      class="pied-breadcrumbs__title pied-breadcrumb-text-primary__colo"
    >
      {{ title }}
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("breadcrumb", ["breadcrumb"]),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    path() {
      const formatMobilePath = (pt = []) => {
        if (pt.length > 2) {
          return [
            pt[0],
            { text: "...", routeName: pt[pt.length - 2]?.routeName },
            pt[pt.length - 1],
          ];
        }
        return pt;
      };
      let pt = [];
      for (let i = 0; i < this.breadcrumb.length; i++) {
        pt.push(this.breadcrumb[i]);
        if (this.$route.name === this.breadcrumb[i].routeName) {
          break;
        }
      }
      if (this.isMobile) return formatMobilePath(pt);
      return pt;
    },
    title() {
      return this.path[this.path.length - 1]?.text || null;
    },
  },
  methods: {
    hasRoute(route) {
      return route.routeName && this.$route.name !== route.routeName;
    },
  },
};
</script>

<style scoped lang="scss">
.pied-breadcrumb__divisor {
  margin: 0px 1px 0px 2px;
  padding-top: 5px;
}
.pied-breadcrumb-current-text__color {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}
.pied-breadcrumb-father-text__color {
  color: rgba(0, 0, 0, 0.5);
}
.pied-breadcrumbs__text-container {
  display: inline-flex;
  align-content: center;
  align-items: baseline;
}

.pied-breadcrumbs__text {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.pied-breadcrumbs__text-hover:hover {
  color: var(--v-button-base) !important;
  opacity: 1;
  cursor: pointer;
}

.pied-breadcrumbs__title {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.92px;
  text-align: left;
  margin-top: 5px;
}
</style>

import { kebabCase } from "lodash";
import { v4 } from "uuid";

export function generateUUID() {
  return v4();
}

export function normalizeString(str = "") {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(
      /[\u0000-\u001F-\u0021-\u002F-\u003A-\u0040-\u005b-\u0060-\u007b-\u009f]/g,
      ""
    );
}

export function normalizeKebabCase(str = "") {
  return kebabCase(str.toUpperCase());
}

export const mask = (value, format) => {
  let [posix, masked, total] = [0, "", format.length];

  for (let i = 0; i <= total - 1; i++) {
    if (format[i] !== "#") {
      masked += format[i];
      continue;
    }

    if (!value || !value[posix]) continue;
    masked += value[posix++];
  }
  return masked;
};

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

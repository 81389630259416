import Icons from "@/constants/Icons";

export default [
  {
    path: "/equipments",
    name: "Equipments",
    redirect: "/equipments/modules",
    component: () => import("@/views/GroupRouter.vue"),
    meta: {
      icon: Icons.VIEWMODULE,
      title: "Equipamentos",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["equipment"],
      isGroup: true,
    },
    children: [
      {
        path: "/modules",
        name: "Modules",
        redirect: "/modules/items",
        component: () => import("@/views/GroupRouter.vue"),
        meta: {
          icon: Icons.VIEWMODULE,
          title: "Módulos",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
          isGroup: true,
        },
        children: [
          {
            path: "/modules/items",
            name: "PageModulesItems",
            meta: {
              type: "module",
              icon: Icons.VIEWMODULE,
              title: "Módulos",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.items"],
            },
            component: () => import("@/views/products/PageProductsItems.vue"),
          },
          {
            path: "/modules/inventory",
            name: "PageModulesInventory",
            meta: {
              type: "module",
              icon: Icons.VIEWMODULE,
              title: "Módulos",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.inventory"],
            },
            component: () =>
              import("@/views/products/PageProductsInventory.vue"),
          },
        ],
      },
      {
        path: "/inverters",
        name: "Inverters",
        redirect: "/inverters/items",
        component: () => import("@/views/GroupRouter.vue"),
        meta: {
          icon: Icons.VIEWMODULE,
          title: "Inversores",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
          isGroup: true,
        },
        children: [
          {
            path: "/inverters/items",
            name: "PageInvertersItems",
            meta: {
              type: "inverter",
              icon: Icons.INVERTER,
              title: "Inversores",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.items"],
            },
            component: () => import("@/views/products/PageProductsItems.vue"),
          },
          {
            path: "/inverters/inventory",
            name: "PageInvertersInventory",
            meta: {
              type: "inverter",
              icon: Icons.INVERTER,
              title: "Inversores",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.inventory"],
            },
            component: () =>
              import("@/views/products/PageProductsInventory.vue"),
          },
        ],
      },
      {
        path: "/stringbox",
        name: "StringBox",
        redirect: "/stringbox/items",
        component: () => import("@/views/GroupRouter.vue"),
        meta: {
          icon: Icons.VIEWMODULE,
          title: "String Box",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
          isGroup: true,
        },
        children: [
          {
            path: "/stringbox/items",
            name: "PageStringBoxItems",
            meta: {
              type: "stringBox",
              icon: Icons.ALPHA1,
              title: "String Box",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.items"],
            },
            component: () => import("@/views/products/PageProductsItems.vue"),
          },
          {
            path: "/stringbox/inventory",
            name: "PageStringBoxInventory",
            meta: {
              type: "stringBox",
              icon: Icons.ALPHA1,
              title: "String Box",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.inventory"],
            },
            component: () =>
              import("@/views/products/PageProductsInventory.vue"),
          },
        ],
      },
      {
        path: "/products",
        name: "Products",
        redirect: "/products/items",
        component: () => import("@/views/GroupRouter.vue"),
        meta: {
          icon: Icons.VIEWMODULE,
          title: "Outros Produtos",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
          isGroup: true,
        },
        children: [
          {
            path: "/products/items",
            name: "PageProductsItems",
            meta: {
              type: "complementaryEquipment",
              icon: Icons.OTHERPRODUCT,
              title: "Outros Produtos",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.items"],
            },
            component: () => import("@/views/products/PageProductsItems.vue"),
          },
          {
            path: "/products/inventory",
            name: "PageProductsInventory",
            meta: {
              type: "complementaryEquipment",
              icon: Icons.OTHERPRODUCT,
              title: "Outros Produtos",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["equipment", "equipment.inventory"],
            },
            component: () =>
              import("@/views/products/PageProductsInventory.vue"),
          },
        ],
      },
      {
        path: "kits",
        name: "Kits",
        meta: {
          type: "kit",
          icon: Icons.DOLLY,
          title: "Kits Fechados",
          local: ["navBar"],
          actions: ["enableClosedKits"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment", "equipment.items"],
        },
        component: () => import("@/views/products/PageProductsItems.vue"),
      },
      {
        path: "historical/:type/:id",
        name: "historical",
        meta: {
          icon: Icons.HISTORIC,
          hiddenMenu: true,
          title: "Histórico",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment", "equipment.items"],
        },
        component: () => import("@/views/products/ProductsHistorical.vue"),
      },
      {
        path: "visualize/:type/:id?",
        name: "EquipmentsAddEdit",
        meta: {
          icon: Icons.VIEWMODULE,
          hiddenMenu: true,
          title: "Equipamentos",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment", "equipment.items"],
        },
        component: () => import("@/views/products/PageProductsAddEdit.vue"),
      },
      {
        path: "kits/automatic",
        name: "PageKitAutomatic",
        meta: {
          icon: Icons.DOLLY,
          hiddenMenu: true,
          title: "Kits Fechados",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment", "equipment.items"],
        },
        component: () => import("@/views/products/PageKitAutomatic.vue"),
      },
    ],
  },
];

function propertyToNumber(dom, propertyName) {
  let domEl;

  if (dom instanceof HTMLElement) {
    domEl = dom;
  } else {
    domEl =
      document.getElementById(dom) ||
      document.querySelector(dom) ||
      document.getElementsByClassName(dom)[0];
  }

  if (domEl) {
    const computedStyle = window.getComputedStyle(domEl);
    const property = computedStyle.getPropertyValue(propertyName);
    return Number(property.replace("px", ""));
  }

  return null;
}

function hexToRgba(hex, alpha = 1) {
  const rgbArray =
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      ?.map((x) => parseInt(x, 16)) || [];

  return [...rgbArray, alpha].join(",");
}

export { propertyToNumber, hexToRgba };

<template>
  <div @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    <v-navigation-drawer
      v-if="pageLoaded && user"
      v-model="navBarModel"
      class="main-nav-bar"
      width="250"
      mini-variant-width="80"
      :mini-variant="mini"
      :expand-on-hover="mini"
      :class="{ navbar__preview: preview }"
      :app="!preview"
      :permanent="permanent"
      :right="positionSideBar === 'right'"
    >
      <template #prepend>
        <NavBarHeaderLogo :custom-logo="logoSrcSm" @click="changePage" />
      </template>
      <v-list shaped :disabled="preview">
        <template v-for="(item, index) in itemsToShow">
          <v-divider v-if="showDivider(item.name)" :key="index" />

          <v-list-item
            v-if="!item.children"
            :to="routeTo(item)"
            @click="groupModel = {}"
            :key="`children_${index}`"
          >
            <v-list-item-icon>
              <v-icon :color="iconColor" size="14px" v-text="item.meta.icon" />
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ item.meta.title }}</span>
              <v-chip
                v-if="item.meta.isBeta"
                x-small
                label
                color="primary"
                class="mx-1 isNew"
              >
                NOVO
              </v-chip>
            </v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            :value="item.path === groupModel.path"
            no-action
            :key="item.path"
            @input="groupModel = item"
          >
            <template #prependIcon>
              <v-icon :color="iconColor" size="14px">{{
                item.meta.icon
              }}</v-icon>
            </template>
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              :to="routeTo(child)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.meta.title }}
                  <v-chip
                    v-if="child.meta.isBeta"
                    x-small
                    label
                    color="primary"
                    class="pa-2 ml-2"
                  >
                    NOVO
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>

        <!-- LINK EXTERNO -->
        <v-list-item v-if="showExternLink" @click="onExternalLink">
          <v-list-item-icon>
            <v-icon :color="iconColor" size="14px">{{ $icons.LINK2 }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ user.role.externalLinkButton.name }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="userActions && userActions.singleSignOnBelEnergy"
          @click="onGenSingleSignOnToken"
        >
          <v-list-item-icon>
            <v-icon :color="iconColor" size="14px">{{ $icons.LINK }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Kits Fechados</v-list-item-title>
        </v-list-item>
      </v-list>

      <template #append>
        <div class="nav-var__footer_container" />
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import { User } from "@/request";
import { checkPermissions, setPermissions } from "@/utils/utils";
import { baseURL } from "@/request/http";
import NavBarHeaderLogo from "@/components/plataform/nav-bar/NavBarHeaderLogo.vue";
import PiedChip from "@/components/global/PiedChip.vue";

export default {
  components: { PiedChip, NavBarHeaderLogo },
  props: {
    permanent: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    customSmallLogo: {
      type: [String, File],
      default: "",
    },
    customBigLogo: {
      type: [String, File],
      default: "",
    },
    gradientColor1: {
      type: String,
      default: "var(--v-colorOneLinearGradient-base)",
    },
    gradientColor2: {
      type: String,
      default: "var(--v-colorTwoLinearGradient-base)",
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  data: function () {
    return {
      groupModel: {},
      itemsToShow: [],
      pageLoaded: false,
      baseURL,
    };
  },
  computed: {
    ...mapState("theme", ["appBar", "mini"]),
    ...mapGetters("theme", {
      theme: "getTheme",
      isPiedAdmin: "isPiedAdmin",
      setting: "setting",
      positionSideBar: "getSideBarPosition",
      sideBar: "getSideBar",
    }),
    ...mapGetters("userData", {
      user: "getUserData",
      isBackOfficeUser: "isBackOfficeUser",
    }),
    isAdmin() {
      return this.user.type === "admin";
    },
    navBarModel: {
      get() {
        return this.appBar;
      },
      set(val) {
        this.updateAppBar(val);
      },
    },
    logo() {
      if (this.customBigLogo && !this.mini) return this.customBigLogo;
      if (this.customSmallLogo && this.mini) return this.customSmallLogo;

      const logo = this.mini
        ? this.theme.companyTheme.smallLogo
        : this.theme.companyTheme.logo;
      const logoPiedAdmin = this.mini
        ? "LogoPiedAdminSmall.png"
        : "LogoPiedAdminBig.png";

      return this.isPiedAdmin ? logoPiedAdmin : logo;
    },
    logoSrcSm() {
      return this.preview && !this.logo?.includes("/app/distribuitors/")
        ? this.logo
        : `${baseURL}${this.logo}?w=sm&q=${this.createDate}`;
    },
    userActions() {
      return this.user?.actions;
    },
    userRole() {
      return this.user?.role;
    },
    isToChangeRoutesTitle() {
      return (
        this.userActions?.customIntegratorSideBar &&
        this.user?.type === "integrator"
      );
    },
    showExternLink() {
      return (
        this.user.role &&
        this.user.actions.externalLink &&
        this.user.role.permissions.includes("externalLinkButton")
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    async userRole(current, old) {
      this.UPDATE_LOADING(true);
      this.pageLoaded = false;
      console.info("reload navbar, role updated...");
      await this.chargeComponent();
      this.UPDATE_LOADING(false);
    },
  },
  async created() {
    try {
      this.UPDATE_LOADING(true);
      await this.chargeComponent();
    } catch (e) {
      console.warn("failed mount navbar" + e);
    } finally {
      this.UPDATE_LOADING(false);
    }
  },
  methods: {
    ...mapMutations(["UPDATE_LOADING"]),
    ...mapActions("NotifyDialog", ["openNotifyError"]),
    ...mapActions("theme", [
      "updateAppBar",
      "updateIsButtonOpenCloseNavBarHidden",
      "updateSideBar",
      "updateDashboardBIUrl",
    ]),
    async chargeComponent() {
      if (this.isAdmin) {
        await this.setDashboardUrl();
      }

      await setPermissions();

      if (this.user.token) {
        await this.readSideBar();
        this.prepareComponent();
      }

      this.pageLoaded = true;
    },

    async readSideBar() {
      try {
        if (this.$route.name === "Login") {
          return;
        }
        this.UPDATE_LOADING(true);
        const ret = await User.readSideBar();
        if (ret.data?.customSideBar && this.user.type === "integrator") {
          this.updateSideBar(ret.data.customSideBar);
        }
      } finally {
        this.UPDATE_LOADING(false);
      }
    },

    async filter(arr, callback) {
      const fail = Symbol();
      return (
        await Promise.all(
          arr.map(async (item) => ((await callback(item)) ? item : fail))
        )
      ).filter((i) => i !== fail);
    },

    async setDashboardUrl() {
      const ret = await User.getDashBIUrl();
      if (!ret.error) {
        this.updateDashboardBIUrl(ret.data?.dashBIUrl);
      }
    },

    prepareComponent() {
      const routes = this.$router.getRoutes().map((item) => {
        return { ...item, meta: { ...item.meta } };
      });

      let allowedRoutes = [];
      for (let route of routes) {
        let basicPerm =
          route.meta?.local?.indexOf("navBar") !== -1 &&
          route.meta?.permissions?.indexOf(this.user.type) !== -1 &&
          route.meta?.permissions?.indexOf(this.user.type) > -1;

        let hideInMenuCheck = false;
        if (route.meta.hiddenMenu) {
          hideInMenuCheck = true;
        }

        if (route.name === "DashboardBI" && !this.theme.dashboardBIUrl) {
          hideInMenuCheck = true;
        }

        let actionCheck = true;
        if (route.meta?.actions?.length) {
          actionCheck = route.meta.actions.reduce((acc, currentAction) => {
            let cur = this.userActions && this.userActions[currentAction];
            if (acc != null) return acc && cur;
            return cur;
          }, null);
        }

        let permissionCheck = true,
          hasPermissionCheck =
            (this.user.type === "integrator" &&
              route.meta?.integratorPermissions) ||
            (this.user.type === "staff" && route.meta?.staffPermissions) ||
            [];

        if (hasPermissionCheck.length)
          permissionCheck = checkPermissions(
            route.meta?.staffPermissions,
            route.meta?.integratorPermissions,
            this.user,
            route.meta?.onePermissionNeeded
          );

        let financingIntegratorCheck = true;
        if (
          route.name === "FinancingIntegrator" &&
          (!this.userActions?.enableCRM ||
            (this.user.type === "integrator" &&
              !this.user?.distribuitor?.crmSettings) ||
            this.user?.distribuitor?.crmSettings?.authBankAndFinancing ===
              "disallowedCreateBankAndFinancingLine")
        )
          financingIntegratorCheck = false;

        let proposalTemplateIntegratorCheck = true;
        if (
          route.name === "IntegratorProposalSettingsTemplates" &&
          (!this.userActions?.enableCRM ||
            this.user?.type !== "integrator" ||
            this.user?.integratorRole !== "manager" ||
            this?.user?.role?.permissions?.every(
              (permission) => permission !== "crm"
            ))
        )
          proposalTemplateIntegratorCheck = false;

        if (route.name === "Sizing") {
          if (this.userActions?.changeSizingName)
            route.meta.title = "Dimensionamento";
          else route.meta.title = "Engenheiro Virtual";
        }

        if (
          basicPerm &&
          permissionCheck &&
          actionCheck &&
          financingIntegratorCheck &&
          !hideInMenuCheck &&
          proposalTemplateIntegratorCheck
        ) {
          allowedRoutes.push(route);
        }
      }

      allowedRoutes.forEach((route) => {
        if (route.meta.isGroup) {
          const childRoutes = allowedRoutes.filter(
            (item) => item.parent?.name === route.name
          );
          if (!route["children"]) route["children"] = [];
          route["children"].push(...childRoutes);
        }
      });

      if (this.isToChangeRoutesTitle) {
        allowedRoutes.map((route) => {
          this.sideBar.map((itemSide) => {
            if (route.name === itemSide.name && !!itemSide.customTitle)
              route.meta.title = itemSide.customTitle;
          });
        });
        allowedRoutes.map((item, index) => {
          let i = this.sideBar.findIndex(
            (itemSide) => item.name === itemSide.name
          );
          if (i >= 0) {
            let f = allowedRoutes.splice(index, 1)[0];
            allowedRoutes.splice(i, 0, { ...f, order: i + 1 });
          }
        });
      } else {
        allowedRoutes.forEach((route) => {
          this.sideBar.forEach((itemSide) => {
            if (route.name === itemSide.name && !!itemSide.title)
              route.meta.title = itemSide.title;
          });
        });
      }

      if (this.isBackOfficeUser) {
        allowedRoutes =
          this.filterRoutesHiddenFromBackofficeUser(allowedRoutes);
      }
      this.itemsToShow = allowedRoutes.filter((item) => !item.parent);

      if (this.isToChangeRoutesTitle) {
        this.itemsToShow.sort((a, b) => a.order - b.order);
      }
    },
    filterRoutesHiddenFromBackofficeUser(routes) {
      const HIDDEN_ROUTES_BY_NAME = [
        "PiedPlans",
        "Equipments",
        "PrecificationSettings",
        "DeliverySettings",
        "RecomendationsSettings",
        "StructuresSettings",
      ];

      const filterHiddenRoutes = (route) => {
        return !HIDDEN_ROUTES_BY_NAME.includes(route.name);
      };

      routes.forEach((route) => {
        if (route.meta?.isGroup) {
          const children = routes.filter(
            (item) => item.parent?.name === route.name
          );
          route.children = children.filter(filterHiddenRoutes);
        }
      });

      return routes.filter(filterHiddenRoutes);
    },
    showDivider(item) {
      return (
        ["Sizing", "Equipments", "PiedPlans"].includes(item) ||
        (item === "Leads" && this.user?.type === "integrator")
      );
    },
    async onGenSingleSignOnToken() {
      const ret = await User.genSingleSignOnToken();
      const url = `https://www.belenus.com.br/?tknEnergy=${ret.data}`;
      window.open(url, "_blank");
    },
    async onExternalLink() {
      window.open(this.user.role.externalLinkButton.link, "_blank");
    },
    async changePage() {
      if (this.preview) return;

      const home = {
        integrator: "DashboardIntegrator",
        admin: "Dashboard",
        staff: "Dashboard",
        piedAdmin: "Distributors",
      };

      if (home[this.user?.type]) {
        if (home[this.user?.type] !== this.$route.name)
          await this.$redirect({ name: home[this.user?.type] });
      } else {
        await this.openNotifyError({
          title: "Erro",
          message: "Houve um erro inesperado, tente novamente mais tarde",
        });
      }
    },
    routeTo(child) {
      return child.name
        ? { name: child.name, params: child.meta?.params }
        : child.path;
    },

    onMouseOver() {
      if (this.preview) return;
      if (!this.isMobile && this.appBar && this.mini) {
        this.updateIsButtonOpenCloseNavBarHidden(true);
      }
    },
    onMouseLeave() {
      if (this.preview) return;
      if (!this.isMobile && this.appBar && this.mini) {
        this.updateIsButtonOpenCloseNavBarHidden(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/nav-bar.scss";

::v-deep .v-list-item__title {
  white-space: normal;
}

.v-navigation-drawer--mini-variant {
  .nav-bar-header-logo {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 28px;
    height: 108px;
  }
}

.nav-var__footer_container {
  content: "";
  height: 10px;
  background: $distributor-gradient;
}

.navbar__preview {
  ::v-deep .v-navigation-drawer__content {
    overflow: hidden;
  }
}

.v-footer {
  font-size: 0.7rem;
  justify-content: center;
}

.v-chip.isNew ::v-deep {
  padding: 0 6px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 600;
}
</style>
